

export function Loading() {



    return (
        <div className="loader">

<div className="apple-loader">
  <div className="apple-loader-inner">
    <div className="apple-loader-inner-inner"></div>
  </div>
</div>
        </div>
    )
}